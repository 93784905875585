import './App.css';
import Home from './components/Home'
import { createTheme, ThemeProvider } from '@mui/material/styles';
//auth Imports
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import  {loginRequest} from './auth'

const SansSeriffonts = "'Arial', sans-serif";
const Seriffonts = "'Times New Roman', serif";

const theme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#E61E28", //arup red
      light: "#FA777C", //lighter arup red
    },
    secondary: {
      main: "#FFFFFF", //bg color
      dark: "#FAFAFA", //darker bg color
    },
    status: {
      danger: "#e53e3e",
      error: "#B60007",
      warn: "#FF9800",
    },
    background: {
      main: "#FFFFFF", //bg color
      dark: "#FAFAFA", //darker bg color
    },
    black: {
      main: "#000000DE",
    },
  },
  typography: {
    fontFamily: SansSeriffonts,
    h1: {
      fontSize: 60,
      fontFamily: Seriffonts,
      color: "#000000DE",
      letterSpacing: -0.5,
    },
    h3: {
      fontSize: 32,
      fontFamily: Seriffonts,
      color: "#000000DE",
      letterSpacing: 0.3,
    },
    caption: {
      fontSize: 12,
      fontFamily: SansSeriffonts,
      letterSpacing: 0.6,
      fontWeight: 400,
      color: "#000000DE",
    },
    subtitle1: {
      fontSize: 16,
      fontFamily: SansSeriffonts,
      color: "#000000DE",
      letterSpacing: 0.5,
      fontWeight: "bold",
    },
    subtitle2: {
      fontSize: 14,
      fontFamily: SansSeriffonts,
      color: "#000000DE",
      letterSpacing: 0.6,
      fontWeight: "bold",
    },
    body1: {
      fontSize: 14,
      fontFamily: SansSeriffonts,
      color: "#00000099",
      letterSpacing: 0.25,
    },
    body2: {
      fontSize: 14,
      fontFamily: SansSeriffonts,
      color: "#767676",
      letterSpacing: 0.4,
    },
    tooltip1: {
      fontSize: 10,
      fontFamily: SansSeriffonts,
      color: "#ffffff",
      letterSpacing: 0.7,
      lineHeight: 14,
      fontWeight: "bold",
    },
    tooltip: {
      fontSize: 10,
      fontFamily: SansSeriffonts,
      color: "#ffffff",
      letterSpacing: 0.7,
    },
    overline: {
      fontSize: 12,
      fontFamily: SansSeriffonts,
      color: "#263238",
      letterSpacing: 0.25,
    },
    button: {
      fontSize: 14,
      fontFamily: SansSeriffonts,
      color: "#00000099",
      letterSpacing: 0.4,
      textTransform: "none",
    },
  },
});

function App() {
  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={loginRequest}
    >
    <ThemeProvider theme={theme}>
        <div className='App'>
          <Home />
        </div>
      </ThemeProvider>
      </MsalAuthenticationTemplate>
  );
}

export default App;
