import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

const classes = {
  infoMessage: {
    marginTop: "37.5vh",
    textAlign: "center",
  },
  infoIcon: {
    color: "grey",
  },
  tableSpace: {
    width: "94%",
    height: "77%",
    marginLeft: "3%",
    marginTop: "3%",
  },
  tableCellDesign: {
    padding: "8px 0 8px 0px",
  },
};

const ValidationTable = (props) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const list = props.results;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, list.length - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return props.results.length == 0 ? (
    <div style={classes.infoMessage}>
      <InfoIcon sx={classes.infoIcon} />
      <div>no validation result</div>
    </div>
  ) : (
    <>
      <div style={classes.tableSpace}>
        <Table size="medium" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell sx={classes.tableCellDesign} align="left">
                <b>Rule name</b>
              </TableCell>
              <TableCell sx={classes.tableCellDesign} align="center">
                <b>Categories</b>
              </TableCell>
              <TableCell sx={classes.tableCellDesign} align="center">
                <b>Parameter</b>
              </TableCell>
              <TableCell sx={classes.tableCellDesign} align="center">
                <b>Rule</b>
              </TableCell>
              <TableCell sx={classes.tableCellDesign} align="center">
                <b>Value</b>
              </TableCell>
              <TableCell sx={classes.tableCellDesign} align="center">
                <b>Error rate</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? list.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : list
            ).map((row, index) => {
              return (
                <TableRow key={row.id}>
                  <TableCell align="left" sx={classes.tableCellDesign}>
                    {row.name}
                  </TableCell>
                  <TableCell align="center" sx={classes.tableCellDesign}>
                    {row.categories.join(", ")}
                  </TableCell>
                  <TableCell align="center" sx={classes.tableCellDesign}>
                    {row.parameter}
                  </TableCell>
                  <TableCell align="center" sx={classes.tableCellDesign}>
                    {row.rule}
                  </TableCell>
                  <TableCell align="center" sx={classes.tableCellDesign}>
                    {row.value
                      ? row.value
                      : row.rangeFrom
                      ? row.rangeFrom + " - " + row.rangeTo
                      : ""}
                  </TableCell>
                  <TableCell align="center" sx={classes.tableCellDesign}>
                    {parseFloat(row.error * 100).toFixed(2)}%
                  </TableCell>
                </TableRow>
              );
            })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 37 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[15, 30, 45, { label: "All", value: -1 }]}
                colSpan={12}
                count={list.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </div>
    </>
  );
};

export default ValidationTable;
