import React from "react";
import {
  Grid,
  Tabs,
  Tab,
  AppBar
} from '@mui/material'

import IFC from "../../assets/IFC_manager_logo.png";
import ARUP from "../../assets/ARUP.png";

const classes = {
  root: {
    flexGrow: 1,
  },
  textColorInherit: {
    width:'13%',
    color: "red",
    fontFamily: "Arial",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "18px",
    letterSpacing: "0.6px",
  },
  appbarPanel: {
    backgroundColor: "white",
  },
  tabs: {
    width:'200%',
    "& button[aria-selected='true']": {
      borderRight: "1px ridge #FFFFFF",
      borderLeft: "1px ridge #FFFFFF",
      
    },
  },
  tabRoot: {
    textTransform: "none",
  }
}


const Navbar = ({value,setValue}) => {

  return (
    <div sx={classes.root}>
      <AppBar position="static" color='secondary'>
        <Grid justify={"left"} container>
          <Grid xs={1} md={2.5} item>
            <img style={{marginTop:'20px', height:'13px', marginLeft:'20px'}} sx={classes.IfcLogo} src={IFC}></img>
          </Grid>
          <Grid xs={5} md={8.4} item>
            <Grid container>
              <Tabs
                sx={classes.tabs}
                onChange={(e, v) => setValue(v)}
                value={value}
                aria-label="Navigation Tabs"
                indicatorColor="none"
              >
                <Tab
                  sx={classes.textColorInherit}
                  label={"Manage rule"}
                />
                <Tab
                  sx={classes.textColorInherit}
                  label={"Validate"}
                />
              </Tabs>
            </Grid>
          </Grid>
          <Grid item xs={1}>
            <Grid container >
              <img style={{height:'35px', marginTop:'5px', marginLeft:'35px'}} src={ARUP}></img>
            </Grid>
          </Grid>
        </Grid>
      </AppBar>
    </div>
  );
};

export default Navbar;