import React, { useState, useEffect } from "react";
// import { makeStyles } from "@material-ui/core/styles";

import {
  TextField,
  Button,
  MenuItem,
  CircularProgress,
  FormHelperText,
} from '@mui/material'
import Select from "react-select";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";

import { insertRule, deleteRule } from "../../api/CosmosApi";

const classes = {
  addRuleForm: {
    marginTop: "20%",
    marginLeft: "10%",
    marginRight: "10%",
  },
  TextArea: {
    marginBottom: "30px",
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#000000",
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "#000000",
    },
  },
  TextAreaRangeStart: {
    width: "45%",
    marginBottom: "30px",
  },
  TextAreaRangeEnds: {
    width: "45%",
    float:'right'
  },
  buttonAdd: {
    borderRadius: "50px",
    marginTop: "10px",
    color: "white",
    backgroundColor: "#000000",
    height: "50px",
    border: "1px ridge",
    "&:hover": {
      backgroundColor: "#000000",
      color: "white",
      border: "1px ridge",
    },
  },
  cancelbuttonEdit: {
    borderRadius: "50px",
    marginTop: "10px",
    width: "47.5%",
    color: "#000000",
    backgroundColor: "white",
    height: "50px",
    border: "1px ridge",
    "&:hover": {
      backgroundColor: "white",
      color: "#000000",
      border: "1px ridge"
    },
  },
  saveButtonEdit: {
    borderRadius: "50px",
    marginTop: "10px",
    width: "47.5%",
    color: "white",
    backgroundColor: "#000000",
    height: "50px",
    border: "1px ridge",
    "&:hover": {
      backgroundColor: "#000000",
      color: "white",
      border: "1px ridge"
    },
  },
  HelperTextDesign: {
    marginLeft: "0px",
  },
  categoriesHelperText: {
    color: "#d32f2f",
    marginBottom: "30px",
  },
}

const AddEditRule = (props) => {
  const init_state = {
    id: uuidv4(),
    name: "",
    categories: [],
    parameter: "",
    rule: "",
    value: "",
    rangeFrom: "",
    rangeTo: "",
  };
  const [currentRule, setCurrentRule] = useState(
    props.selectedRule || init_state
  );

  const [loading, setLoading] = useState(false);
  const ruleList = [
    "exists",
    "greater",
    "less",
    "equal",
    "starts",
    "range",
    "contains",
    "range(dates only)",
  ]; // HArdcoded for now
  const [checkValid, setCheckValid] = useState(false);

  useEffect(() => {
    if (props.selectedRule) {
      setCurrentRule(props.selectedRule);
    } else {
      setCurrentRule(init_state);
    }
  }, [props.selectedRule]);

  const customStyles = {
    control: (base) => ({
      ...base,
      height: 60,
      minHeight: 35,
      marginBottom:
        checkValid && currentRule["categories"].length == 0 ? "0px" : "30px",
      whiteSpace: "nowrap",
      overflowY: "scroll",
      border:
        checkValid && currentRule.categories.length == 0
          ? "1px solid #d32f2f"
          : "1px solid #D3D3D3",
      boxShadow: "none",
      borderColor:
        checkValid && currentRule.categories.length == 0 ? "#d32f2f" : "#167595",
      "&:hover": {
        borderColor:
          checkValid && currentRule.categories.length == 0 ? "#d32f2f" : "#167595",
      },
      "&:focus": {
        borderColor:
          checkValid && currentRule.categories.length == 0 ? "#d32f2f" : "#167595",
      },
    }),
    menu: (base) => ({
      ...base,
      zIndex: 100,
    }),
  };

  const updateRule = (key, value) => {
    if (key == "rangeFrom") {
      setCurrentRule({
        ...currentRule,
        value:
          currentRule["rule"] == "range"
            ? value + "-" + currentRule["rangeTo"]
            : moment(value, "YYYY-MM-DD").format("DD/MM/YYYY") +
              "-" +
              moment(currentRule["rangeTo"], "YYYY-MM-DD").format("DD/MM/YYYY"),
        [key]: value,
      });
    } else if (key == "rangeTo") {
      setCurrentRule({
        ...currentRule,
        value:
          currentRule["rule"] == "range"
            ? currentRule["rangeFrom"] + "-" + value
            : moment(currentRule["rangeFrom"], "YYYY-MM-DD").format(
                "DD/MM/YYYY"
              ) +
              "-" +
              moment(value, "YYYY-MM-DD").format("DD/MM/YYYY"),
        [key]: value,
      });
    } else {
      setCurrentRule({
        ...currentRule,
        [key]: value,
      });
    }
  };

  const saveChanges = async () => {
    // Check if Form Valid
    if (checkIsValid()) {
      //Insert Single Rule into Cosmos DB
      setLoading(true);
      let insertFlag = await insertRule(currentRule);
      if (insertFlag.statusCode == 201) {
        props.setRules([...props.rules, currentRule]);
        resetFormState();
        setLoading(false);
      } else {
        console.log(
          "Error inserting rule in database.. Status Code:",
          insertFlag.statusCode
        );
      }
    }
  };
  const resetFormState = () => {
    setCurrentRule(init_state);
    props.setSelectedRule(undefined);
    props.setAction("add");
    setCheckValid(false);
  };

  const cancelChanges = () => {
    resetFormState();
  };

  const updateChanges = async () => {
    if (checkIsValid()) {
      setLoading(true);
      // Current Azure Cosmos doesn't support update operation. Need to delete and re-insert doc
      const deleteFlag = await deleteRule(currentRule);
      if (deleteFlag.statusCode == 204) {
        const insertFlag = await insertRule(currentRule);
        if (insertFlag.statusCode == 201) {
          // Update rules in redux
          let prevRules = props.rules;
          prevRules.map((rule, index) => {
            if (rule.id == currentRule.id) {
              prevRules[index] = currentRule;
            }
          });
          props.setRules(prevRules);
          resetFormState();
          setLoading(false);
        }
      }
    }
  };

  const checkIsValid = () => {
    setCheckValid(true);
    let flag = false;
    if (
      currentRule.name.trim() !== "" &&
      currentRule.parameter.trim() !== "" &&
      currentRule.categories.length != 0 &&
      currentRule.rule.trim() !== ""
    ) {
      if (currentRule.rule == "range") {
        if (
          currentRule.rangeFrom.trim() !== "" &&
          currentRule.rangeTo.trim() !== ""
        ) {
          flag = true;
        }
      } else if (currentRule.rule == "exists") {
        flag = true;
      } else {
        if (currentRule.value.trim() !== "") {
          flag = true;
        }
      }
    }
    return flag;
  };

  return (
    <>
      <div style={classes.addRuleForm}>
        <TextField
          id="outlined-basic"
          label="Add rule name"
          variant="outlined"
          sx={classes.TextArea}
          value={currentRule["name"]}
          onChange={(e) => updateRule("name", e.target.value)}
          error={checkValid && currentRule["name"].trim() == ""}
          helperText={
            checkValid && currentRule["name"].trim() == ""
              ? "Name field cannot be empty"
              : ""
          }
          FormHelperTextProps={{
            sx : classes.HelperTextDesign
          }}
          fullWidth
        />
        <Select
          isMulti
          name="options"
          placeholder={<div>Select categories</div>}
          getOptionValue={(options) => options.id}
          options={props.categories}
          value={currentRule["categories"]}
          onChange={(e) => updateRule("categories", e)}
          styles={customStyles}
        />
        {checkValid && currentRule["categories"].length == 0 && (
          <FormHelperText
            id="component-helper-text"
            sx={classes.categoriesHelperText}
          >
            Categories field cannot be empty
          </FormHelperText>
        )}
        <TextField
          label="Add parameter"
          variant="outlined"
          sx={classes.TextArea}
          value={currentRule["parameter"]}
          onChange={(e) => updateRule("parameter", e.target.value)}
          error={checkValid && currentRule["parameter"].trim() == ""}
          helperText={
            checkValid && currentRule["parameter"].trim() == ""
              ? "Parameter field cannot be empty"
              : ""
          }
          FormHelperTextProps={{
            sx : classes.HelperTextDesign
          }}
          fullWidth
        />
        <TextField
          label="Select rule"
          variant="outlined"
          sx={classes.TextArea}
          value={currentRule["rule"]}
          onChange={(e) => updateRule("rule", e.target.value)}
          error={checkValid && currentRule["rule"].trim() == ""}
          helperText={
            checkValid && currentRule["rule"].trim() == ""
              ? "Rule field cannot be empty"
              : ""
          }
          FormHelperTextProps={{
            sx : classes.HelperTextDesign
          }}
          fullWidth
          select
        >
          {ruleList.map((value, key) => (
            <MenuItem key={key} value={value}>
              {value}
            </MenuItem>
          ))}
        </TextField>
        {currentRule["rule"] == "range" ||
        currentRule["rule"] == "range(dates only)" ? (
          <>
            <TextField
              label={
                currentRule["rule"] == "range" ? "Range start" : "Start date"
              }
              type={currentRule["rule"] == "range" ? "text" : "date"}
              inputProps={{
                max: currentRule["rangeTo"],
              }}
              variant="outlined"
              style={classes.TextAreaRangeStart}
              sx={classes.TextArea}
              value={currentRule["rangeFrom"] || ""}
              onChange={(e) => updateRule("rangeFrom", e.target.value)}
              error={checkValid && currentRule["rangeFrom"].trim() == ""}
              InputLabelProps={{
                shrink: true,
              }}
              helperText={
                checkValid && currentRule["rangeFrom"].trim() == ""
                  ? "Range field cannot be empty"
                  : ""
              }
              FormHelperTextProps={{
                sx : classes.HelperTextDesign
              }}
            />
            <TextField
              label={currentRule["rule"] == "range" ? "Range end" : "End date"}
              type={currentRule["rule"] == "range" ? "text" : "date"}
              inputProps={{
                min: currentRule["rangeFrom"],
              }}
              variant="outlined"
              style={classes.TextAreaRangeEnds}
              sx={classes.TextArea}
              value={currentRule["rangeTo"]}
              onChange={(e) => updateRule("rangeTo", e.target.value)}
              error={checkValid && currentRule["rangeTo"].trim() == ""}
              InputLabelProps={{
                shrink: true,
              }}
              helperText={
                checkValid && currentRule["rangeTo"].trim() == ""
                  ? "Range field cannot be empty"
                  : ""
              }
              FormHelperTextProps={{
                sx : classes.HelperTextDesign
              }}
            />
          </>
        ) : (
          <TextField
            label="Expected value"
            variant="outlined"
            sx={classes.TextArea}
            value={currentRule["value"]}
            onChange={(e) => updateRule("value", e.target.value)}
            error={
              checkValid &&
              currentRule["rule"] !== "exists" &&
              currentRule["value"].trim() == ""
            }
            helperText={
              checkValid &&
              currentRule["rule"] !== "exists" &&
              currentRule["value"].trim() == ""
                ? "Value field cannot be empty"
                : ""
            }
            FormHelperTextProps={{
              sx : classes.HelperTextDesign
            }}
            fullWidth
          />
        )}
        {props.action == "add" ? (
          <Button
            variant="outlined"
            sx={classes.buttonAdd}
            onClick={() => saveChanges()}
            fullWidth
          >
            {loading ? <CircularProgress /> : <>Save new rule</>}
          </Button>
        ) : (
          <>
            <Button
              variant="outlined"
              sx={classes.cancelbuttonEdit}
              style={{ marginRight: "5%" }}
              onClick={() => cancelChanges()}
              fullWidth
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              sx={classes.saveButtonEdit}
              onClick={() => updateChanges()}
              fullWidth
            >
              {loading ? <CircularProgress /> : <>Save</>}
            </Button>
          </>
        )}
      </div>
    </>
  );
};

export default AddEditRule;
