import React from "react";
import { CosmosClient } from "@azure/cosmos";

const createClientConnection = () => {
  const endpoint = process.env.REACT_APP_ENDPOINT;
  const key = process.env.REACT_APP_DB_KEY;
  const databaseId = process.env.REACT_APP_DATABASEID;
  const client = new CosmosClient({ endpoint, key });
  const database = client.database(databaseId);
  return database;
};
const databaseClient = createClientConnection();

export const getAllItemsFromContainer = async (containerId) => {
  try {
    const container = await databaseClient.container(containerId);
    const querySpec = {
      query: "SELECT * from c",
    };
    const { resources } = await container.items.query(querySpec).fetchAll();
    return resources;
  } catch (err) {
    console.log("Error while getting items from container. Description: ", err);
  }
};

export const insertRule = async (rule) => {
  try {
    const containerId = "rules";
    const container = await databaseClient.container(containerId);
    const response = await container.items.create(rule);
    return response;
  } catch (err) {
    console.log("Error occured while inserting rules in database");
  }
};

export const deleteRule = async (rule) => {
  try {
    const containerId = "rules";
    const container = await databaseClient.container(containerId);
    // Delete Rule First and re-insert. Update is not supported currently
    const requestOptions = {
      partitionKey: "id",
    };
    const response = await container.item(rule.id, rule.id).delete();
    return response;
  } catch (err) {
    console.log("Error occured while deleting rule from database. Error:", err);
  }
};

export const queryResult = async (id) => {
  try {
    const containerId = "result";
    const container = await databaseClient.container(containerId);
    const querySpec = {
      query: "SELECT * from c where c.id='" + id + "'",
    };
    // read all items in the Items container
    const { resources: items } = await container.items
      .query(querySpec)
      .fetchAll();
    return items;
  } catch (err) {
    console.log(
      "Error occured while getting result from database. Error:",
      err
    );
  }
};
