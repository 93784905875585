import React, { useState } from "react";
import { useMsal } from "@azure/msal-react";
import { useDropzone } from "react-dropzone";
import { Grid, Paper, Typography } from "@mui/material";

import BackupIcon from "@mui/icons-material/Backup";
import { v4 as uuidv4 } from "uuid";
import { InitialMessage } from "./DropMessage";
import { postBimProcess } from "../../api";
import Loading from "../Loading";
import ValidationTable from "./ValidationTable";
import { queryResult } from "../../api/CosmosApi";

const classes = {
  root: {
    marginLeft: "56px",
    marginRight: "56px",
  },
  itemConatiner: {
    height: "75vh",
  },
  loadingContainer: {
    height: "100%",
    width: "100%",
  },
  paper: {
    height: "100%",
    width: "100%",
    boxShadow: "none",
    overflow: "auto",
    backgroundColor: "white",
    position: "relative",
    scrollbarColor: "red yellow",
    "&::-webkit-scrollbar": {
      width: "5px",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 5px rgb(255, 251, 251)",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#BEBEBE",
      borderRadius: "10px",
    },
  },
  header: {
    marginTop: "50px",
    marginBottom: "0px",
    fontSize: "18px",
    fontWeight: "700",
    lineHeight: "40px",
  },
  inputFileContainer: {
    backgroundColor: "#efefef",
    top: "35%",
    left: "5%",
    width: "90%",
    height: "30%",
    border: "1px dashed #9B9B9B",
    position: "absolute",
  },
  messageIconContainer: {
    margin: "auto",
    marginTop: "13%",
    textAlign: "center",
  },
  uploadIcon: {
    textAlign: "center",
  },
};

const Validate = (props) => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [results, setResults] = useState([]);
  // get auth instance
  const { instance, accounts } = useMsal();

  const setLoadingScreen = (isValid, info) => {
    setLoading(isValid);
    setMessage(info);
  };

  // Make async call after 10 seconds to Cosmos DB and verify if results received
  const waitAndFetchResults = async (id) => {
    var refreshId = await setInterval(async function () {
      var response = await queryResult(id);
      if (!response.error && response.length !== 0) {
        clearInterval(refreshId);
        const validate = response[0].all_results;
        setResults(validate);
        setLoadingScreen(false, "");
      }
    }, 10000);
  };

  const onDrop = async (acceptedFiles) => {
    try {
      if (acceptedFiles.length > 0) {
        setLoadingScreen(true, "Please wait while we fetch results...");
        const formData = new FormData();
        for (const name in acceptedFiles) {
          formData.append(name, acceptedFiles[name]);
        }
        const id = uuidv4();
        const url = process.env.REACT_APP_BIM_API + "/validate/" + id;
        let param = formData;
        const response = await postBimProcess({
          url,
          param,
          instance,
          accounts,
        });
        if (!response.error) {
          // Search Cosmos for results and update table
          waitAndFetchResults(id);
        } else {
          console.log(
            "Error while fetching results from API. Description: ",
            response.error
          );
        }
      }
    } catch (err) {
      console.log("Error uploading file. Description:", err);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: [".zip"],
    multiple: false,
  });

  return (
    <>
      {loading && <Loading show={loading} message={message} />}
      <div style={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={8}>
            <Typography variant="h6" sx={classes.header}>
              Validate result
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h6" sx={classes.header}>
              Upload file to validate
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={3} sx={classes.container}>
          <Grid item xs={8} sx={classes.itemConatiner}>
            <Paper sx={classes.paper}>
              <ValidationTable results={results} />
            </Paper>
          </Grid>
          <Grid item xs={4} csx={classes.itemConatiner}>
            <Paper sx={classes.paper}>
              <div style={classes.inputFileContainer} {...getRootProps()}>
                <div style={classes.messageIconContainer}>
                  <BackupIcon sx={classes.uploadIcon} />
                  <InitialMessage />
                </div>
                <input {...getInputProps()} />
              </div>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Validate;
