import React from 'react'
import Navbar from '../Navbar'
import ManageRule from '../ManageRule.js'
import Validate from '../Validate'

const Home = props => {
    const [value, setValue] = React.useState(0);
    return (
        <>
        <Navbar 
            value = {value}
            setValue ={setValue}
        />
        {value == 0?(
            <ManageRule />
        ):(
            <Validate />
        )}
        </>
    )
}

export default Home