import React from "react";
import { Typography } from "@mui/material";

const classes = {
  loading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    height: 198,
    width: 496,
    border: "2px solid #eee",
  },
  progress: {
    width: "100%",
  },
  initMessage: {
    fontSize: "16px",
  },
};

export const InitialMessage = (props) => {
  return (
    <>
      <Typography variant="body2" sx={classes.initMessage} gutterBottom>
        Drag and Drop or{" "}
        <a style={{ cursor: "pointer", textDecoration: "underline" }}>
          click to browse
        </a>
      </Typography>
    </>
  );
};
