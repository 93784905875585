import React, { useState, useEffect } from "react";
import {
  Grid,
  Paper,
  Typography
} from '@mui/material'

import RuleList from "./RuleList.js";
import AddEditRule from "./AddEditRule.js";
import { getAllItemsFromContainer } from "../../api/CosmosApi.js";

const classes = {
  root: {marginLeft:'56px', marginRight:'56px'},
  itemConatiner: {
    height: "75vh",
  },
  loadingContainer: {
    height: "100%",
    width: "100%",
  },
  paper: {
    height: "100%",
    width: "100%",
    boxShadow: "none",
    overflow: "auto",
    backgroundColor: "white",
    position: "relative",
    scrollbarColor: "red yellow",
    "&::-webkit-scrollbar": {
      width: "5px",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 5px rgb(255, 251, 251)",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#BEBEBE",
      borderRadius: "10px",
    },
  },
  header: {
      marginTop: '50px',
      marginBottom:'0px',
      fontSize: '18px',
      fontWeight: '700',
      lineHeight: '40px'
  }
}

const ManageRule = props => {
    const [rules, setRules] = useState([])
    const [action, setAction] = useState('add')
    const [selectedRule, setSelectedRule] = useState(undefined)
    const [categories, setCategories] = useState([])

    useEffect(()=>{
      // Getting all categories from database
      getAllItemsFromContainer('categories').then(response=>{
        setCategories(convertCategoriesToList(response))
      }).catch(err=>{
        console.log('API Error while fetching Categories from Cosmos DB. Description: ', err)
      })
      //Getting Existing stored rules from database
      getAllItemsFromContainer('rules').then(response=>{
        setRules(response)
      }).catch(err=>{
        console.log('API Error while fetching Rules from Cosmos DB. Description: ', err)
      })
    },[]) 

    const convertCategoriesToList = (categories) => {
      let categoriesOptions = []
      categories.map((item) => {
        let group = { 
          id: item.id, 
          label: item.name,
          options:[{
            id: item.id, 
          label: item.name,
          }]
        };
        if (item.hasOwnProperty("subcategories")) {
          let index=1
          item.subcategories.map((subItem) => {
            group.options.push({ id: item.id+'-'+index, label: subItem });
            index=index+1
          });
        }
        categoriesOptions.push(group);
      });
      return categoriesOptions
    }

    const resetRuleForm = () => {
      setSelectedRule(undefined)
    }
    
    return (
        <div style={classes.root}>
          <Grid container spacing={3}>
          <Grid item xs={8}>
      <Typography 
        variant='h6'
        sx={classes.header}
      >
        Rule list
      </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography 
        variant='h6'
        sx={classes.header}
      >
        Add new rule
      </Typography>
      </Grid>
      </Grid>
      <Grid container spacing={3} sx={classes.container}>
        <Grid item xs={8} sx={classes.itemConatiner}>
          <Paper sx={classes.paper}>
            <RuleList rules={rules} setRules={setRules} setAction={setAction} selectedRule={selectedRule} setSelectedRule={setSelectedRule} resetRuleForm={()=> resetRuleForm()}/>
          </Paper>
        </Grid>
        <Grid item xs={4} sx={classes.itemConatiner}>
          <Paper sx={classes.paper}>
            <AddEditRule 
              categories={categories}
              rules={rules}
              setRules={setRules}
              action={action}
              setAction={setAction}
              selectedRule={selectedRule}
              setSelectedRule={setSelectedRule}
            />
          </Paper>
        </Grid>
      </Grid>
    </div>
    )
}

export default ManageRule