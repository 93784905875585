import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  TablePagination,
  TableFooter,
  CircularProgress,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
  TextField,
  IconButton,
} from '@mui/material'
import FileCopyIcon from '@mui/icons-material/FileCopy';
import InfoIcon from '@mui/icons-material/Info';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import { deleteRule, insertRule } from "../../api/CosmosApi";

const classes = {
  infoMessage: {
    marginTop: "37.5vh",
    textAlign: "center",
  },
  infoIcon: {
    color: "grey",
  },
  searchBar: {
    marginTop: "3%",
    marginLeft: "3%",
    width: "30%",
  },
  TextArea: {
    marginBottom: "30px",
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#000000",
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "#000000",
    },
  },
  tableSpace: {
    width: "94%",
    height: "77%",
    marginLeft: "3%",
  },
  tableCellDesign: {
    padding: '0 0 0 18px',
    textOverflow:'ellipsis', overflow:'hidden', maxWidth:'60px', whiteSpace:'nowrap'
    
  },
  tableCellIconDesign: {
    padding: '0 0 0 -4px'
  }
}

const RuleList = (props) => {
  const [list, setList] = useState(props.rules || [])
  const [filter, setFilter] = useState('')
  const [loading, setLoading] = useState(false)
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(9);

  useEffect(()=>{
    if(filter.trim()!='')
    {
      let newList = props.rules.filter(n=>n.name.toUpperCase().includes(filter.toUpperCase()))    // Case-insensitive Search
      setList(newList)
    }
    else
    {
      setList(props.rules)
    }
  },[filter, props.rules])

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, list.length - page * rowsPerPage);
  
  const getHighlightedText = (text, highlight) => {
      // Split on highlight term and include term into parts, ignore case
      const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
      return <span> { parts.map((part, i) => 
          <span key={i} style={part.toLowerCase() === highlight.toLowerCase() ? { fontWeight: 'bold' } : {} }>
              { part }
          </span>)
      } </span>;
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const CommaSeperatedCategories = (categories) => {
    let ls = ''
    categories.map((item, index)=>{
      if(index!=categories.length-1)
      {
        ls = ls+item.label+','
      }
      else
      {
        ls = ls+item.label
      }
    })
    return ls;
  }
  
  const editRule = (index, row) => {
    props.setAction('edit')
    props.setSelectedRule(row)
  }

  const deleteCurrentRule = async(index,row) => {
    setLoading(true)
    const flag = await deleteRule(row)
    if(flag.statusCode == 204)
    {
      let newRules = props.rules.filter(n=>n.id!==row.id)
      props.setRules(newRules)
      console.log('Deleted rule from DB')
      setLoading(false)
      // If rule is opened in Rule form for editing and user clicks on delete button in RulesList Table, then reset Rule Form
      if(props.selectedRule && props.selectedRule.id == row.id)
      {
        props.resetRuleForm()
      }
    }
  }
  
  function addAfter(array, index, newItem) {
    return [
        ...array.slice(0, index),
        newItem,
        ...array.slice(index)
    ];
}

  const duplicateRule = async(row, index) => {
    const newRule = {
      ...row,
      id:uuidv4(),
    }
    let insertFlag = await insertRule(newRule);
    if (insertFlag.statusCode == 201) {
      props.setRules(addAfter(props.rules,index,newRule))
    }
  } 
  
  return (
    <>
      {
         props.rules.length == 0?(
             <div style={{marginTop: "37.5vh",textAlign: "center"}}>
             <InfoIcon sx={classes.infoIcon}/>
             <div>
                 No rules have been added
             </div>
             </div>
         ):loading?(<div style={{marginTop: "37.5vh",textAlign: "center"}}>
          <CircularProgress sx={classes.infoIcon}/>
          <div>
              Please wait while we delete this rule from database ..
          </div>
          </div>
         ):(
        <>
          <div style= {{marginTop: "3%", marginLeft: "3%", width: "30%"}}>
            <TextField
              id="outlined-basic"
              label="Search rule name"
              variant="outlined"
              autoComplete="off"
              sx={classes.TextArea}
              // sx={{
              //   fieldset: { borderColor: "black" }
              // }}
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              fullWidth
            />
          </div>
          <div style={{
            width: "94%",
            height: "77%",
            marginLeft: "3%"
          }}>
            <Table
              sx={classes.table}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell classes={{body:classes.tableCellDesign}} align="left">
                    <b>Rule name</b>
                  </TableCell>
                  <TableCell classes={{body:classes.tableCellDesign}} align="left">
                    <b>Categories</b>
                  </TableCell>
                  <TableCell classes={{body:classes.tableCellDesign}} align="left">
                    <b>Parameter</b>
                  </TableCell>
                  <TableCell classes={{body:classes.tableCellDesign}} align="left">
                    <b>Rule</b>
                  </TableCell>
                  <TableCell classes={{body:classes.tableCellDesign}} align="left">
                    <b>Expected value</b>
                  </TableCell>
                  <TableCell classes={{body:classes.tableCellDesign}} align="right">
                    <b>Duplicate</b>
                  </TableCell>
                  <TableCell classes={{body:classes.tableCellDesign}} align="right">
                    <b>Edit&nbsp;&nbsp;&nbsp;</b>
                  </TableCell>
                  <TableCell classes={{body:classes.tableCellDesign}} align="right">
                    <b>Delete</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  (rowsPerPage > 0
                    ? list.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : list
                  ).map((row, index) => {
                  return(
                  <TableRow key={row.id}>
                    <TableCell component="th" classes={{body:classes.tableCellDesign}}>
                      {getHighlightedText(row.name,filter)}
                    </TableCell>
                    <TableCell align="left" classes={{body:classes.tableCellDesign}} >{CommaSeperatedCategories(row.categories)}</TableCell>
                    <TableCell align="left" classes={{body:classes.tableCellDesign}} >{row.parameter}</TableCell>
                    <TableCell align="left" classes={{body:classes.tableCellDesign}} >{row.rule}</TableCell>
                    <TableCell align="left" classes={{body:classes.tableCellDesign}} >{row.value}</TableCell>
                    <TableCell align="right" classes={{body:classes.tableCellIconDesign}} >
                    <IconButton
                        aria-label="Duplicate Rule"
                        onClick={() => duplicateRule(row, index)}
                      >
                      <FileCopyIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell align="right" classes={{body:classes.tableCellIconDesign}} >
                      <IconButton
                        aria-label="Edit Rule"
                        onClick={() => editRule(index, row)}
                      >
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell align="right">
                      <IconButton
                        aria-label="Delete Rule"
                        onClick={() => deleteCurrentRule(index,row)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                )})}
                {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={12} />
                </TableRow>
              )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[9, 18, 27, { label: 'All', value: -1 }]}
                    colSpan={12}
                    count={list.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: { 'aria-label': 'rows per page' },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </div>
        </>
         )
      }
    </>
  );
};

export default RuleList;
