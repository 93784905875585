import { loginRequest } from "./auth";

export const AuthToken = async (instance, accounts) => {
  const token = await instance.acquireTokenSilent({
    ...loginRequest,
    account: accounts[0]
  });
  let bearer = "Bearer " + token.accessToken;
  return bearer;
};


