import {AuthToken} from "../authToken";

export const postBimProcess = async ({ url, param, instance, accounts }) => {
    const bearer = await AuthToken(instance, accounts);
    const requestOptions = {
      method: "POST",
      headers: {
        //"Content-Type": "multipart/form-data",  // Not required for file Object
        Authorization: bearer,
      },
      body: param,
    };
    return await fetch(url, requestOptions)
      .then(async (response) => {
        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = await response.json();
          return { data: error, error: true };
        } else {
          const data = await response.text();
          return { data: data, error: false };
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };