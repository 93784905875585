import React from "react";
import {
  Backdrop,
  CircularProgress,
  Typography,
  Grid
} from '@mui/material'

const classes = {
  backdrop: {
    zIndex: (theme) => theme.zIndex.drawer + 1,
    color: "red",
  },
  loadingStyle: {
    color: "#353535",
  },
}

const Loading = ({ show, message = "" }) => {
  return (
    <Backdrop sx={classes.backdrop} open={show}>
      <Grid
        container
        direction='column'
        alignItems='center'
        justifyContent='center'
        spacing={2}
      >
        <Grid item>
          <CircularProgress color='secondary' />
        </Grid>
        <Grid item>
          <Typography
            display='block'
            variant='h6'
            sx={classes.loadingStyle}
          >
            {message}
          </Typography>
        </Grid>
      </Grid>
    </Backdrop>
  );
};

export default Loading;
